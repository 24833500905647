<template>
  <div class="container mt-4">
    <b-row>
      <b-col class="d-flex align-items-center">
        <back-button/>
        <h5 class="ml-2 mb-0">
          {{ translations.details.page_title }}
        </h5>
      </b-col>
    </b-row>
    <div class="my-3 d-flex align-items-center justify-content-between">
      <div>
        <span class="mr-1">
          <feather v-if="!allCollapsed" type="chevron-down"/>
          <feather v-else type="chevron-right"/>
        </span>
        <b-link @click="expandAll">{{ translations.details.expand_all }}</b-link>&nbsp;/
        <b-link @click="collapseAll">{{ translations.details.collapse_all }}</b-link>
        {{ translations.details.all }}
      </div>
      <div>
        <b-button
          v-if="!details.claim_write_off_task_id"
          data-testid="btn-write-off"
          variant="secondary"
          class="float-right"
          @click="openWriteOffModal"
          :disabled="loading">
          {{ translations.details.btn_modal_write_off }}
        </b-button>
      </div>
    </div>

    <claim-write-off-task-modal
    :is-visible="writeOffModalVisible"
    :details="details"
    @created="fetchDetails"
    @update:isVisible="writeOffModalVisible = $event"/>

    <details-section-box
      class="mt-1"
      :disabled="loading"
      :title="translations.details.section_task_details.title"
      :expanded="sectionsExpanded.taskDetails"
      @toggled="toggleSection('taskDetails', ...arguments)">
      <details-section-task-details :details="details" @updated="onDetailsUpdated"/>
    </details-section-box>

    <details-section-box
      class="mt-3"
      :disabled="loading"
      :title="translations.details.section_finance_information.title"
      :expanded="sectionsExpanded.financeInformation"
      @toggled="toggleSection('financeInformation', ...arguments)">
      <details-section-finance-information :details="details"/>
    </details-section-box>

    <details-section-box
      class="mt-3"
      :disabled="loading"
      :title="translations.details.section_notes.title"
      :expanded="sectionsExpanded.notes"
      @toggled="toggleSection('notes', ...arguments)">
      <details-section-notes :details="details"/>
    </details-section-box>
  </div>
</template>

<script>
import errorHandler from '@/mixins/errorHandler';
import { parseResponseError } from '@/http/parsers/parse_response';
import translations from '@/translations';
import BackButton from '@/components/BackButton.vue';
import service from '@/services/finance-service';
import ClaimWriteOffTaskModal from '@/components/FinanceOperations/ClaimWriteOffTaskModal.vue';
import DetailsSectionBox from './detailsSectionBox.vue';
import DetailsSectionTaskDetails from './detailsSectionTaskDetails.vue';
import DetailsSectionFinanceInformation from './detailsSectionFinanceInformation.vue';
import DetailsSectionNotes from './detailsSectionNotes.vue';

export default {
  name: 'ClaimFollowUpTaskDetails',
  mixins: [ errorHandler ],
  components: {
    BackButton,
    DetailsSectionBox,
    DetailsSectionTaskDetails,
    DetailsSectionFinanceInformation,
    DetailsSectionNotes,
    ClaimWriteOffTaskModal,
  },
  data() {
    return {
      translations: translations.finance_operations.claims_follow_up_tasks,
      loading: true,
      sectionsExpanded: {
        taskDetails: true,
        financeInformation: true,
        notes: true,
      },
      details: {},
      writeOffModalVisible: false,
    };
  },
  async beforeMount() {
    await this.fetchDetails();
  },
  computed: {
    allCollapsed() {
      let ret = true;
      Object.keys(this.sectionsExpanded).forEach(k => {
        if (this.sectionsExpanded[k]) {
          ret = false;
        }
      });
      return ret;
    },
  },
  methods: {
    async fetchDetails() {
      try {
        this.details = await service.getClaimFollowUpTask(this.$route.params.id);
      } catch (err) {
        this.$noty.error(`${this.translations.errors.fetch_details}: ${parseResponseError(err)}`);
      } finally {
        this.loading = false;
      }
    },
    toggleSection(name, expanded) {
      this.sectionsExpanded[name] = expanded;
    },
    onDetailsUpdated(newDetails) {
      this.details = newDetails;
    },
    expandAll() {
      Object.keys(this.sectionsExpanded).forEach(k => {
        this.sectionsExpanded[k] = true;
      });
    },
    collapseAll() {
      Object.keys(this.sectionsExpanded).forEach(k => {
        this.sectionsExpanded[k] = false;
      });
    },
    openWriteOffModal() {
      this.writeOffModalVisible = true;
    },
  },
};
</script>
