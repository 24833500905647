<template>
  <div>
    <loading-content v-if="!allLoaded"/>
    <div v-else>
      <div v-if="notes.length > 0" class="notes-container border">
        <div v-for="(note, key) in notes" :key="key">
          <div class="row p-2">
            <div class="col-3">
              <div class="d-flex flex-column">
                <div class="p-1">{{ note.user_email }}</div>
                <div class="p-1">{{ formatDatetime(note.created_at) }}</div>
              </div>
            </div>
            <div class="col-9">
              <div class="p-1">{{ note.description }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex mt-2">
        <b-form-group
          class="p-0 m-0 flex-grow-1"
          :invalid-feedback="handleError('newNote')"
          :state="!validateNewNote || isValid('newNote')">
          <b-form-textarea
            rows="3"
            max-rows="8"
            no-auto-shrink trim
            :disabled="saving"
            v-model="newNote"/>
        </b-form-group>
        <b-button class="align-self-end text-nowrap ml-2" @click="saveNewNote">
          {{ translations.details.section_notes.save_note }}
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import translations from '@/translations';
import { formatDatetime } from '@/helpers/finance';
import { parseResponseError } from '@/http/parsers/parse_response';
import LoadingContent from '@/components/LoadingContent.vue';
import service from '@/services/finance-service';
import errorHandler from '@/mixins/errorHandler';
import { required } from 'vuelidate/lib/validators';

export default {
  name: 'DetailsSectionNotes',
  components: {
    LoadingContent,
  },
  mixins: [ errorHandler ],
  props: {
    details: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      translations: translations.finance_operations.claims_follow_up_tasks,
      allLoaded: false,
      canScrollBottom: false,
      notes: [],
      saving: false,
      validateNewNote: false,
      newNote: '',
    };
  },
  updated() {
    if (!this.canScrollBottom) {
      return;
    }

    const container = this.$el.querySelector('.notes-container');
    container.scrollTop = container.scrollHeight;
    this.canScrollBottom = false;
  },
  validations() {
    return this.rules;
  },
  async beforeMount() {
    await this.fetchNotes();
    this.allLoaded = true;
  },
  computed: {
    rules() {
      return {
        newNote: { required },
      };
    },
  },
  methods: {
    formatDatetime,
    async fetchNotes() {
      try {
        const response = await service.getClaimFollowUpTaskNotes(this.$route.params.id, {
          order_by: 'created_at',
          order_descending: false,
        });
        this.notes = response.notes;
        this.canScrollBottom = true;
      } catch (err) {
        this.$noty.error(`${this.translations.errors.fetch_notes}: ${parseResponseError(err)}`);
      }
    },
    async saveNewNote() {
      this.validateNewNote = true;
      if (!this.newNote) {
        return;
      }

      try {
        this.saving = true;
        const newNote = await service.createClaimFollowUpTaskNote(this.$route.params.id, { description: this.newNote });
        this.newNote = '';
        this.canScrollBottom = true;
        this.notes = [ ...this.notes, newNote ];
        this.validateNewNote = false;
      } catch (err) {
        this.$noty.error(`${this.translations.errors.save_note}: ${parseResponseError(err)}`);
      }
      this.saving = false;
    },
  },
};
</script>

<style lang="scss" scoped>
  .notes-container {
    max-height: 300px;
    overflow-x: hidden;
    overflow-y: auto
}
</style>
