<template>
  <div>
    <loading-content v-if="!allLoaded"/>
    <div v-else>
      <b-row v-if="errorMsg">
        <b-col>{{ errorMsg }}</b-col>
      </b-row>
      <b-row v-else>
        <b-col cols="3">
          <b>{{ translations.program_id }}</b>
          <p>
            <router-link :to="{ name: 'OpsSearchProgram', query: { program_id: claim.program_id } }">
              {{ claim.program_id || '-' }}
            </router-link>
          </p>
        </b-col>
        <b-col cols="3">
          <b>{{ translations.account_uuid }}</b>
          <p>{{ claim.patient_data.account_uuid || '-' }}</p>
        </b-col>
        <b-col cols="3">
          <b>{{ translations.statement_id }}</b>
          <p>
            <router-link :to="{ name: 'FinanceStatementsDetails', params: { statementID: claimBatch.statement.id } }">
              {{ claimBatch.statement.id || '-' }}
            </router-link>
          </p>
        </b-col>
        <b-col cols="12" class="mt-2">
          <financial-list
            :value="1"
            :header-data="TRANSACTIONS_TABLE_HEADERS"
            :data="transactions"
            :havePagination="false"
            :no-items-message="translations.no_transactions"/>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import translations from '@/translations';
import { formatDate, formatValue } from '@/helpers/finance';
import { parseResponseError } from '@/http/parsers/parse_response';
import FinancialList, { associateHeaderDataItem } from '@/components/Financial/FinancialList.vue';
import LoadingContent from '@/components/LoadingContent.vue';

export default {
  name: 'DetailsSectionFinanceInformation',
  components: { LoadingContent, FinancialList },
  props: {
    details: {
      type: Object,
      required: true,
    },
  },
  created() {
    this.TRANSACTIONS_TABLE_HEADERS = [
      associateHeaderDataItem(this.translations.transactions_header_id, 'id'),
      associateHeaderDataItem(this.translations.transactions_header_created_at, 'created_at'),
      associateHeaderDataItem(this.translations.transactions_header_event_date, 'event_date'),
      associateHeaderDataItem(this.translations.transactions_header_billable_date, 'billable_date'),
      associateHeaderDataItem(this.translations.transactions_header_transaction_type, 'transaction_type'),
      associateHeaderDataItem(this.translations.transactions_header_unit, 'unit'),
      associateHeaderDataItem(this.translations.transactions_header_therapy_name, 'therapy_name'),
      associateHeaderDataItem(this.translations.transactions_header_transaction_value, 'transaction_value'),
      associateHeaderDataItem(this.translations.transactions_header_transaction_value_type, 'transaction_value_type'),
    ];
  },
  data() {
    return {
      translations: translations.finance_operations.claims_follow_up_tasks.details.section_finance_information,
      allLoaded: false,
      errorMsg: '',
      claim: {},
      claimBatch: {},
      transactions: [],
    };
  },
  async beforeMount() {
    if (this.hasClaim()) {
      let success = await this.fetchClaim();
      if (success) {
        success = await this.fetchClaimsBatch();
      }
      if (success) {
        await this.fetchTransactions();
      }
    } else {
      this.errorMsg = this.translations.missing_claim;
    }
    this.allLoaded = true;
  },
  methods: {
    formatDate,
    hasClaim() {
      return !!this.details?.claim?.id;
    },
    async fetchClaim() {
      try {
        const apiClaims = await this.$store.dispatch('Financial/getClaims', { id: this.details.claim.id });

        if (apiClaims?.data?.data?.claims?.length !== 1
          || apiClaims?.data?.data?.claims[0].id !== this.details.claim.id
        ) {
          this.$noty.error(this.translations.error_fetching_claim);
        } else {
          [ this.claim ] = apiClaims.data.data.claims;
          return true;
        }
      } catch (err) {
        const errMsg = `${this.translations.error_fetching_claim}: ${parseResponseError(err)}`;
        this.errorMsg = errMsg;
        this.$noty.error(errMsg);
      }
      return false;
    },
    async fetchClaimsBatch() {
      try {
        const claimsBatchID = this.claim.claims_batch_id;
        const apiBatch = await this.$store.dispatch('Financial/getClaimsBatch', { claimsBatchID });

        if (apiBatch?.data?.data?.id !== this.claim.claims_batch_id) {
          this.$noty.error(this.translations.error_fetching_claim);
        } else {
          this.claimBatch = apiBatch.data.data;
          return true;
        }
      } catch (err) {
        const errMsg = `${this.translations.error_fetching_claim}: ${parseResponseError(err)}`;
        this.errorMsg = errMsg;
        this.$noty.error(errMsg);
      }
      return false;
    },
    async fetchTransactions() {
      const apiTransactions = await this.$store.dispatch('Financial/getTransactions', { claim_id: this.claim.id });
      if (!apiTransactions?.transactions) {
        this.transactions = [];
      } else {
        apiTransactions.transactions.forEach(t => {
          t.created_at = formatDate(t.created_at);
          t.event_date = formatDate(t.event_date);
          t.billable_date = formatDate(t.billable_date);
          t.transaction_value = formatValue(t.transaction_value);
        });
        this.transactions = apiTransactions.transactions;
      }
    },
  },
};
</script>
