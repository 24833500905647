<template>
  <b-modal
    id="modal-write-off-task-form"
    centered
    :title="translations.modal_create.title"
    v-model="opened"
    size="xl"
    @hide="handleClose">
    <b-row class="px-3 my-2">
      <b-col>
        <b>{{ translations.modal_create.claim_account }}</b>
        <p>{{ modalDetails.claim_account_name }}</p>
      </b-col>
      <b-col>
        <b>{{ translations.modal_create.claim_id }}</b>
        <p>{{ modalDetails.claim_unique_key }}</p>
      </b-col>
      <b-col>
        <b>{{ translations.modal_create.billed_amount }}</b>
        <p>{{ modalDetails.billed_amount }}</p>
      </b-col>
      <b-col>
        <b>{{ translations.modal_create.paid_amount }}</b>
        <p>{{ modalDetails.paid_amount }}</p>
      </b-col>
      <b-col>
        <b>{{ translations.modal_create.write_off_amount }}</b>
        <p>{{ modalDetails.write_off_amount }}</p>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="6">
        <b-form-group label-class="p-0" :label="translations.modal_create.reason">
          <b-select v-model="form.reason" :options="reasonOptions" />
        </b-form-group>
      </b-col>
    </b-row>

    <template #modal-footer>
      <b-button variant="outline-primary" class="float-right" @click="handleClose">
        {{ translations.modal_create.btn_cancel }}
      </b-button>
      <b-button variant="primary" class="float-right" :disabled="formIsDisabled" @click="handleSave">
        {{ translations.modal_create.btn_save }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import translations from '@/translations';
import service from '@/services/finance-service';
import { parseResponseError } from '@/http/parsers/parse_response';
import errorHandler from '@/mixins/errorHandler';
import { CLAIM_WRITE_OFF_TASK_REASONS } from '@/constants/financeOperations';
import { formatValue } from '@/helpers/finance';

export default {
  name: 'ClaimWriteOffTaskModal',
  mixins: [ errorHandler ],
  props: {
    isVisible: {
      type: Boolean,
      required: true,
    },
    details: {
      type: Object,
      required: true,
    },
  },
  watch: {
    isVisible: {
      handler() {
        this.opened = this.isVisible;
      },
    },
  },
  data() {
    return {
      translations: translations.finance_operations.claim_write_off_tasks,
      loading: false,
      opened: this.isVisible,
      form: {
        reason: '',
      },
    };
  },
  computed: {
    modalDetails() {
      return {
        claim_account_name: this.details.claim_account?.account_name || '-',
        claim_unique_key: this.details.claim?.unique_key || '-',
        billed_amount: formatValue(this.details.claim?.value) || '-',
        paid_amount: formatValue(this.details.claim?.paid_value) || '-',
        write_off_amount: formatValue(this.details.claim_write_off_task_amount) || '-',
      };
    },
    reasonOptions() {
      const opts = [ { value: '', text: '' } ];

      Object.keys(CLAIM_WRITE_OFF_TASK_REASONS).forEach(key => {
        opts.push({ value: key, text: CLAIM_WRITE_OFF_TASK_REASONS[key] });
      });

      return opts;
    },
    formIsDisabled() {
      return this.$v.form.$invalid || this.loading;
    },
    rules() {
      return { form: { reason: { required } } };
    },
  },
  validations() {
    return this.rules;
  },
  methods: {
    handleClose() {
      this.opened = false;
      this.$emit('update:isVisible', false);
    },
    async handleSave() {
      this.loading = true;
      try {
        await service.createClaimWriteOffTask(this.details.id, this.form);
        this.handleClose();
        this.$emit('created');
        this.$noty.success(this.translations.success.create);
      } catch (err) {
        this.$noty.error(`${this.translations.errors.create}: ${parseResponseError(err)}`);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
