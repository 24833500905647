<template>
  <b-card :body-class="expandedCopy ? '' : 'd-none'" header-class="py-1">
    <template #header>
      <b-button
        class="pl-0"
        style="outline: 0"
        variant="_not_exists_"
        @click="onHeaderClick">
        <feather v-if="expandedCopy" type="chevron-down"/>
        <feather v-else type="chevron-right"/>
        <b class="ml-1">{{ title }}</b>
      </b-button>
    </template>
    <loading-content v-if="disabled"/>
    <slot v-else/>
  </b-card>
</template>

<script>
import LoadingContent from '@/components/LoadingContent.vue';

export default {
  name: 'DetailsSectionBox',
  components: {
    LoadingContent,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    expanded: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      expandedCopy: this.expanded,
    };
  },
  watch: {
    expanded(value) {
      this.expandedCopy = value;
    },
  },
  methods: {
    onHeaderClick() {
      this.expandedCopy = !this.expandedCopy;
      this.$emit('toggled', this.expandedCopy);
    },
  },
};
</script>
