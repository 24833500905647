<template>
  <div>
    <div class="d-flex align-items-center">
      <div class="flex-grow-1 mr-2">
        <b-form-group
          label-class="p-0"
          :label="translations.status">
          <b-select
            v-model="form.status"
            :options="statusOptions"/>
        </b-form-group>
      </div>
      <div class="flex-grow-1 mr-2">
        <b-form-group
          label-class="p-0"
          :label="translations.next_action">
          <b-select
            v-model="form.next_action"
            :options="nextActionOptions"/>
        </b-form-group>
      </div>
      <div class="flex-grow-1 mr-2">
        <b-form-group
          label-class="p-0"
          :label="translations.next_action_due_date">
          <VueCtkDateTimePicker format="YYYY-MM-DD"
                                :min-date="minDateAvailable"
                                :label="translations.next_action_due_date_placeholder"
                                formatted="ll"
                                :no-button-now="true"
                                :only-date="true"
                                v-model="form.next_action_due_date"/>
        </b-form-group>
      </div>
      <div class="justify-content-end">
        <b-button
          variant="secondary"
          :disabled="saving || !details.id"
          @click="onSave">
          {{ translations.save }}
        </b-button>
      </div>
    </div>
    <hr>
    <b-row>
      <b-col cols="3">
        <b>{{ translations.claim_account_assignee }}</b>
        <p>{{ details.claim_account.assignee || '-' }}</p>
      </b-col>
      <b-col cols="3">
        <b>{{ translations.claim_account_name }}</b>
        <p>{{ details.claim_account.account_name || '-' }}</p>
      </b-col>
      <b-col cols="3">
        <b>{{ translations.claim_account_payer_external_id }}</b>
        <p>{{ details.claim_account.payer_external_id || '-' }}</p>
      </b-col>
      <b-col cols="3">
        <b>{{ translations.task_id }}</b>
        <p>{{ details.id }}</p>
      </b-col>
      <b-col cols="3">
        <b>{{ translations.created_at }}</b>
        <p>{{ formatDate(details.created_at) }}</p>
      </b-col>
      <b-col cols="3">
        <b>{{ translations.completed_date }}</b>
        <p>{{ formatDate(details.completed_date) || '-' }}</p>
      </b-col>
      <b-col cols="3">
        <b>{{ translations.claim_account_payer_email }}</b>
        <p>
          {{ details.claim_account.payer_email || '-' }}
          <b-button
            class="text-muted p-0 pl-1"
            variant="link"
            @click="copyToClipboard(details.claim_account.payer_email)">
            <feather type="copy" aria-label="Copy to clipboard"/>
          </b-button>
        </p>
      </b-col>
      <b-col cols="3">
        <b>{{ translations.claim_account_csm_email }}</b>
        <p>
          {{ details.claim_account.csm_email || '-' }}
          <b-button
            class="text-muted p-0 pl-1"
            variant="link"
            @click="copyToClipboard(details.claim_account.csm_email)">
            <feather type="copy" aria-label="Copy to clipboard"/>
          </b-button>
        </p>
      </b-col>
      <b-col cols="3">
        <b>{{ translations.write_off_task_id }}</b>
        <p>{{ details.claim_write_off_task_id || '-' }}</p>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import translations from '@/translations';
import { formatDate } from '@/helpers/finance';
import { CLAIM_FOLLOW_UP_TASK_STATUS, CLAIM_FOLLOW_UP_NEXT_ACTIONS } from '@/constants/financeOperations';
import moment from 'moment/moment';
import service from '@/services/finance-service';
import { parseResponseError } from '@/http/parsers/parse_response';
import { cloneDeep } from 'lodash';
import copyToClipboard from '@/scripts/tools/accessibility';

export default {
  name: 'DetailsSectionTaskDetails',
  props: {
    details: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      translations: translations.finance_operations.claims_follow_up_tasks.details.section_task_details,
      translationsFullPage: translations.finance_operations.claims_follow_up_tasks,
      saving: false,
      form: {
        status: this.details.status,
        next_action: this.details.next_action,
        next_action_due_date: this.details.next_action_due_date,
      },
    };
  },
  watch: {
    details: {
      handler() {
        this.form.status = this.details.status;
        this.form.next_action = this.details.next_action;
        this.form.next_action_due_date = this.details.next_action_due_date;
      },
      deep: true,
    },
  },
  computed: {
    minDateAvailable() {
      return moment().utc().format('YYYY-MM-DD');
    },
    statusOptions() {
      const ret = [];
      Object.keys(CLAIM_FOLLOW_UP_TASK_STATUS).forEach(key => {
        ret.push({ value: key, text: CLAIM_FOLLOW_UP_TASK_STATUS[key] });
      });

      return ret;
    },
    nextActionOptions() {
      const ret = [ { value: '', text: '' } ];
      Object.keys(CLAIM_FOLLOW_UP_NEXT_ACTIONS).forEach(key => {
        ret.push({ value: key, text: CLAIM_FOLLOW_UP_NEXT_ACTIONS[key] });
      });

      return ret;
    },
  },
  methods: {
    formatDate,
    copyToClipboard,
    async onSave() {
      try {
        this.saving = true;

        const payload = cloneDeep(this.form);
        if (payload.next_action_due_date) {
          payload.next_action_due_date = moment(payload.next_action_due_date).format('YYYY-MM-DD');
        }

        const updated = await service.updateClaimFollowUpTask(this.details.id, payload);
        this.$emit('updated', updated);
        this.$noty.success(this.translationsFullPage.success.save);
      } catch (err) {
        this.$noty.error(`${this.translationsFullPage.errors.save}: ${parseResponseError(err)}`);
      } finally {
        this.saving = false;
      }
    },
  },
};
</script>
